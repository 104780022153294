import {cloneDeep} from 'lodash-es';
import {IOrderRuntime} from '../runtime/runtime';
import {ICloneable, IMergable} from '../types';
import {IOrder} from './order.types';
import {ThirdPartyFeatureType} from '../third-party-feature';

class Order implements IOrder, IMergable<Order>, ICloneable<Order> {
    private readonly  _id: number | null;
    private readonly _networkId: number;
    private _advertiserId: number;
    private _advertiserMarginId: number | undefined;
    private _agencyId: number | null = null;
    private _campaignIds: Array<number> = [];
    private _calculatedDailyBudget: number | undefined;
    private _calculatedDailyImpressions: number | undefined;
    private _calculatedDailyClicks: number | undefined;
    private _dailyBudget: number | null = null;
    private _dailyImpressions: number | null = null;
    private _dailyClicks: number | null = null;
    private _dailyPacing: boolean = false;
    private _deleted: boolean = false;
    private _lastUpdate: number | undefined;
    private _name: string;
    private _pace: number | undefined;
    private _runtimePacing: boolean = false;
    private _runtimePacingFactor: number | null = null;
    private _runtimeBudgets: Array<IOrderRuntime> = [];
    private _totalBudget: number | null = null;
    private _totalImpressions: number | null = null;
    private _totalClicks: number | null = null;
    private _footfallStoreConfigId: number | undefined;
    private _dsaPaid: string | null = null;
    private _dsaBehalf: string | null = null;
    private _activeThirdPartyFeatures: Array<ThirdPartyFeatureType> = [];

    constructor(
        id: number | null,
        advertiserId: number,
        networkId: number,
        name: string,
    ) {
        this._id = id;
        this._advertiserId = advertiserId;
        this._networkId = networkId;
        this._name = name;
    }

    get id(): number | null {
        return this._id;
    }

    get campaignIds(): Array<number> {
        return this._campaignIds;
    }

    set campaignIds(value: Array<number>) {
        this._campaignIds = value;
    }

    get calculatedDailyBudget(): number | undefined {
        return this._calculatedDailyBudget;
    }

    set calculatedDailyBudget(value: number | undefined) {
        this._calculatedDailyBudget = value;
    }

    get calculatedDailyImpressions(): number | undefined {
        return this._calculatedDailyImpressions;
    }

    set calculatedDailyImpressions(value: number | undefined) {
        this._calculatedDailyImpressions = value;
    }

    get calculatedDailyClicks(): number | undefined {
        return this._calculatedDailyClicks;
    }

    set calculatedDailyClicks(value: number | undefined) {
        this._calculatedDailyClicks = value;
    }

    get dailyBudget(): number | null {
        return this._dailyBudget;
    }

    set dailyBudget(value: number | null) {
        this._dailyBudget = value;
    }

    get dailyImpressions(): number | null {
        return this._dailyImpressions;
    }

    set dailyImpressions(value: number | null) {
        this._dailyImpressions = value;
    }

    get dailyClicks(): number | null {
        return this._dailyClicks;
    }

    set dailyClicks(value: number | null) {
        this._dailyClicks = value;
    }

    get dailyPacing(): boolean {
        return this._dailyPacing;
    }

    set dailyPacing(value: boolean) {
        this._dailyPacing = value;
    }

    get deleted(): boolean {
        return this._deleted;
    }

    set deleted(value: boolean) {
        this._deleted = value;
    }

    get lastUpdate(): number | undefined {
        return this._lastUpdate;
    }

    set lastUpdate(value: number | undefined) {
        this._lastUpdate = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get networkId(): number {
        return this._networkId;
    }

    get advertiserId(): number {
        return this._advertiserId;
    }

    set advertiserId(value: number) {
        this._advertiserId = value;
    }

    get advertiserMarginId(): number | undefined {
        return this._advertiserMarginId;
    }

    set advertiserMarginId(value: number | undefined) {
        this._advertiserMarginId = value;
    }

    get agencyId(): number | null {
        return this._agencyId;
    }

    set agencyId(value: number | null) {
        this._agencyId = value;
    }

    get pace(): number | undefined {
        return this._pace;
    }

    set pace(value: number | undefined) {
        this._pace = value;
    }

    get runtimePacing(): boolean {
        return this._runtimePacing;
    }

    set runtimePacing(value: boolean) {
        this._runtimePacing = value;
    }

    get runtimePacingFactor(): number | null {
        return this._runtimePacingFactor;
    }

    set runtimePacingFactor(value: number | null) {
        this._runtimePacingFactor = value;
    }

    get runtimeBudgets(): Array<IOrderRuntime> {
        return this._runtimeBudgets;
    }

    set runtimeBudgets(value: Array<IOrderRuntime>) {
        this._runtimeBudgets = value;
    }

    get totalBudget(): number | null {
        return this._totalBudget;
    }

    set totalBudget(value: number | null) {
        this._totalBudget = value;
    }

    get totalImpressions(): number | null {
        return this._totalImpressions;
    }

    set totalImpressions(value: number | null) {
        this._totalImpressions = value;
    }

    get totalClicks(): number | null {
        return this._totalClicks;
    }

    set totalClicks(value: number | null) {
        this._totalClicks = value;
    }

    get dsaPaid(): string | null {
        return this._dsaPaid;
    }

    set dsaPaid(value: string | null) {
        this._dsaPaid = value;
    }

    get dsaBehalf(): string | null {
        return this._dsaBehalf;
    }

    set dsaBehalf(value: string | null) {
        this._dsaBehalf = value;
    }

    get activeThirdPartyFeatures(): Array<ThirdPartyFeatureType> {
        return this._activeThirdPartyFeatures;
    }

    set activeThirdPartyFeatures(thirdPartyFeatures: Array<ThirdPartyFeatureType>) {
        this._activeThirdPartyFeatures = thirdPartyFeatures;
    }

    public get footfallStoreConfigId(): number | undefined {
        return this._footfallStoreConfigId;
    }

    public set footfallStoreConfigId(value: number | undefined) {
        this._footfallStoreConfigId = value;
    }

    public merge(source: Order): void {
        this.name = source.name;
        this.advertiserId = source.advertiserId;
        this.advertiserMarginId = source.advertiserMarginId;
        this.agencyId = source.agencyId;
        this.campaignIds = source.campaignIds;
        this.calculatedDailyBudget = source.calculatedDailyBudget;
        this.calculatedDailyImpressions = source.calculatedDailyImpressions;
        this.calculatedDailyClicks = source.calculatedDailyClicks;
        this.dailyBudget = source.dailyBudget;
        this.dailyImpressions = source.dailyImpressions;
        this.dailyClicks = source.dailyClicks;
        this.dailyPacing = source.dailyPacing;
        this.deleted = source.deleted;
        this.lastUpdate = source.lastUpdate;
        this.pace = source.pace;
        this.runtimePacing = source.runtimePacing;
        this.runtimePacingFactor = source.runtimePacingFactor;
        this.runtimeBudgets = cloneDeep(source.runtimeBudgets);
        this.totalBudget = source.totalBudget;
        this.totalImpressions = source.totalImpressions;
        this.totalClicks = source.totalClicks;
        this.footfallStoreConfigId = source.footfallStoreConfigId;
        this.dsaPaid = source.dsaPaid;
        this.dsaBehalf = source.dsaBehalf;
    }

    public clone(): Order {
        const newModel: Order = new Order(
            this.id,
            this.advertiserId,
            this.networkId,
            this.name,
        );
        newModel.merge(this);

        return newModel;
    }
}

export {Order};
